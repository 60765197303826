'use client';
import React, { useState, useEffect } from 'react';
import { Card, CardContent, CardHeader, CardTitle, CardDescription } from "./components/ui/card";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "./components/ui/table";
import { Badge } from "./components/ui/badge";
import { Button } from "./components/ui/button";
import {
  ChevronDown,
  ChevronUp,
  Clock,
  Package,
  MapPin,
  AlertOctagon,
  Info,
  Truck,
  AlertCircle
} from "lucide-react";
import { ApplicationLayout } from './components/application-layout';
import { Loading } from './components/loading';
import { cn } from "./lib/utils";
import { useParams } from 'react-router-dom';


interface AddressInfo {
  origin: {
    city: string;
    state: string;
    zipCode: string;
  };
  destination: {
    city: string;
    state: string;
    zipCode: string;
  };
}

interface EventCodeInfo {
  machineType: string;
  equipmentDescription: string;
  stopTheClockScan: boolean;
  mailType: string;
  mailLevel: string;
  mailPhase: string;
  processDescription: string;
  userDescription: string;
  trackingTitle: string;
}

interface Scan {
  scan_date_time: string;
  scan_event_code: string;
  scan_facility_name: string;
  scan_facility_city: string;
  scan_facility_state: string;
  scan_facility_zip: string;
  scan_facility_locale_key: string;
  scanner_type: string;
  mail_phase: string;
  handling_event_type: string;
  eventCodeInfo?: EventCodeInfo | null;
  machine_name: string;
}

interface TrackingData {
  piece_id: string;
  mail_class: string;
  mail_shape: string;
  edoc_job_id: string | null;
  edoc_submitter_crid: string;
  parent_container_edoc_imcb: string[];
  parent_tray_edoc_imct: string[];
  anticipated_delivery_date: string;
  expected_delivery_date: string;
  start_the_clock_date: string;
  start_the_clock_facility_city: string;
  start_the_clock_facility_locale_key: string;
  start_the_clock_facility_name: string;
  start_the_clock_facility_state: string;
  start_the_clock_facility_zip: string;
  scans: Scan[];
  imb: string;
}

interface EnvelopeData {
  createdAt: string;
  id: string;
  senderId: string;
  batchId: string;
  serialNum: number;
  imbNum: string;
  barcode: string;
  userId: string;
  originalAddressId: string;
  validatedAddressId: string;
  originalAddress: {
    createdAt: string;
    name: string;
    streetAddress: string;
    secondaryAddress?: string;
    city: string;
    state: string;
    ZIPCode: string;
    id: string;
  };
  validatedAddress: {
    createdAt: string;
    id: string;
    name: string;
    streetAddress: string;
    secondaryAddress?: string;
    city: string;
    state: string;
    ZIPCode: string;
  };
  sender: {
    createdAt: string;
    name: string;
    city: string;
    state: string;
    ZIPCode: string;
    id: string;
    userId: string;
    originalAddressId: string;
    validatedAddressId: string;
    validatedAddress: {
      createdAt: string;
      id: string;
      name: string;
      streetAddress: string;
      secondaryAddress?: string;
      city: string;
      state: string;
      ZIPCode: string;
    };
  };
}

interface TrackingResponse {
  message: string | null;
  data: TrackingData;
  addressInfo: AddressInfo;
}

type DeliveryStatus = 'DELIVERED' | 'IN_TRANSIT' | 'OUT_FOR_DELIVERY' | 'DELAYED' | 'NOT_STARTED';

const statusConfig = {
  DELIVERED: {
    color: "bg-green-100 text-green-800 dark:bg-green-900 dark:text-green-200",
    text: "Delivered",
    icon: Package
  },
  IN_TRANSIT: {
    color: "bg-blue-100 text-blue-800 dark:bg-blue-900 dark:text-blue-200",
    text: "In Transit",
    icon: Truck
  },
  OUT_FOR_DELIVERY: {
    color: "bg-purple-100 text-purple-800 dark:bg-purple-900 dark:text-purple-200",
    text: "Out for Delivery",
    icon: Truck
  },
  DELAYED: {
    color: "bg-yellow-100 text-yellow-800 dark:bg-yellow-900 dark:text-yellow-200",
    text: "Delayed",
    icon: Clock
  },
  NOT_STARTED: {
    color: "bg-gray-100 text-gray-800 dark:bg-gray-900 dark:text-gray-200",
    text: "Not Started",
    icon: Clock
  }
};
const App = () => {
  const [trackingData, setTrackingData] = useState<TrackingData | null>(null);
  const [addressInfo, setAddressInfo] = useState<AddressInfo | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [expandedRows, setExpandedRows] = useState<{ [key: number]: boolean }>({});
  const params = useParams();
  useEffect(() => {
    if (params.id) {
      fetchData(params.id);
    }
  }, [params.id]);

  const fetchData = async (id: string) => {
    try {
      const response = await fetch(`https://dev.kyp.sh/api/track/${id}`);
      const result = await response.json();

      if (!response.ok) {
        setError("Envelope not found. Make sure you have the correct ID.");
      } else if (result.data) {
        setTrackingData(result.data);
        setAddressInfo(result.addressInfo);
      } else if (!result.data) {
        setTrackingData(null);
        setAddressInfo(result.addressInfo);
      } else {
        setError("An unexpected error occurred.");
      }
    } catch (err) {
      setError("Failed to fetch tracking data. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  const getDeliveryStatus = (): DeliveryStatus => {
    if (!trackingData) return 'NOT_STARTED';

    const deliveryDate = new Date(trackingData.expected_delivery_date + 'T00:00:00');
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    deliveryDate.setHours(0, 0, 0, 0);

    if (trackingData.scans.some(scan => scan.eventCodeInfo?.stopTheClockScan)) {
      return 'DELIVERED';
    } else if (deliveryDate < today) {
      return 'DELAYED';
    } else if (deliveryDate.getTime() === today.getTime()) {
      return 'OUT_FOR_DELIVERY';
    }
    return 'IN_TRANSIT';
  };

  const toggleRowExpansion = (index: number) => {
    setExpandedRows(prev => ({ ...prev, [index]: !prev[index] }));
  };

  const StatusBadge = ({ status }: { status: DeliveryStatus }) => {
    const config = statusConfig[status];
    const Icon = config.icon;
    return (
      <Badge variant="secondary" className={cn("text-sm font-medium px-3 py-1", config.color)}>
        <Icon className="w-4 h-4 mr-1 inline-block" />
        {config.text}
      </Badge>
    );
  };

  if (isLoading) return <ApplicationLayout>
    <Loading />
  </ApplicationLayout>;

  return (
    <ApplicationLayout>
      <div className="container mx-auto p-6 space-y-8">
        {error ? (
          <Card className="border border-gray-200 dark:border-gray-700 shadow-sm">
            <CardHeader>
              <CardTitle className="flex items-center text-red-600 dark:text-red-400">
                <AlertOctagon className="mr-2 h-5 w-5" />
                Tracking Error
              </CardTitle>
              <CardDescription className="dark:text-gray-400">{params.id}</CardDescription>
            </CardHeader>
            <CardContent>
              <p className="text-red-600 dark:text-red-400">{error}</p>
            </CardContent>
          </Card>
        ) : (
          <>
            <Card className="border border-gray-200 dark:border-gray-800 shadow-sm rounded-lg">
              <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-7">
                <CardTitle className="flex items-center space-x-2">
                  <MapPin className="h-5 w-5" />
                  <span>Delivery Information</span>
                </CardTitle>
                <StatusBadge status={getDeliveryStatus()} />
              </CardHeader>
              <CardContent className="pt-0">
                {trackingData ? (
                  <div className="space-y-6">
                    <div className="border-b border-gray-200 dark:border-gray-800 pb-6">
                      <h3 className="text-sm font-medium text-gray-500 dark:text-gray-400 mb-2">
                        Expected Delivery
                      </h3>
                      <p className="text-xl md:text-2xl font-semibold dark:text-white">
                        {new Date(trackingData.expected_delivery_date + 'T00:00:00')
                          .toLocaleDateString('en-US', {
                            weekday: 'long',
                            year: 'numeric',
                            month: 'long',
                            day: 'numeric'
                          })}
                      </p>
                    </div>
                    <div className="grid grid-cols-1 gap-6 md:grid-cols-2 md:gap-8">
                      <div>
                        <h3 className="text-sm font-medium text-gray-500 dark:text-gray-400 mb-2">From</h3>
                        <p className="font-medium dark:text-white">
                          {addressInfo?.origin.city}, {addressInfo?.origin.state} {addressInfo?.origin.zipCode}
                        </p>
                      </div>
                      <div>
                        <h3 className="text-sm font-medium text-gray-500 dark:text-gray-400 mb-2">To</h3>
                        <p className="font-medium dark:text-white">
                          {addressInfo?.destination.city}, {addressInfo?.destination.state} {addressInfo?.destination.zipCode}
                        </p>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="flex items-center text-yellow-600 dark:text-yellow-400">
                    <Info className="h-5 w-5 mr-2" />
                    <span>Envelope has not been processed by USPS</span>
                  </div>
                )}
              </CardContent>
            </Card>

            <Card className="border border-gray-200 dark:border-gray-800 shadow-sm">
              <CardHeader className="pb-6">
                <CardTitle className="flex items-center space-x-2">
                  <Package className="h-5 w-5" />
                  <span>Tracking History</span>
                </CardTitle>
              </CardHeader>
              <CardContent>
                {/* Desktop view */}
                <div className="hidden md:block overflow-x-auth -mx-6">
                  <Table>
                    <TableHeader>
                      <TableRow className="border-gray-200 dark:border-gray-700 text-left">
                        <TableHead className="w-[250px]">Date/Time</TableHead>
                        <TableHead>Status</TableHead>
                        <TableHead>Location</TableHead>
                        <TableHead className="w-[100px]">Details</TableHead>
                      </TableRow>
                    </TableHeader>
                    <TableBody>
                      {trackingData?.scans.map((scan, index) => (
                        <React.Fragment key={index}>
                          <TableRow className="border-gray-200 dark:border-gray-700">
                            <TableCell className="font-medium">
                              <div className="flex flex-col">
                                <span className="dark:text-white">
                                  {new Date(scan.scan_date_time).toLocaleDateString('en-US', {
                                    weekday: 'long',
                                    month: 'long',
                                    day: 'numeric',
                                    year: 'numeric'
                                  })}
                                </span>
                                <span className="text-sm text-gray-500 dark:text-gray-400">
                                  {new Date(scan.scan_date_time).toLocaleTimeString('en-US', {
                                    hour: 'numeric',
                                    minute: '2-digit',
                                    hour12: true
                                  })}
                                </span>
                              </div>
                            </TableCell>
                            <TableCell className="dark:text-white">
                              {scan.eventCodeInfo?.trackingTitle || scan.mail_phase}
                            </TableCell>
                            <TableCell>
                              <div className="flex flex-col">
                                <span className="dark:text-white">{scan.scan_facility_name}</span>
                                <span className="text-sm text-gray-500 dark:text-gray-400">
                                  {scan.scan_facility_city}, {scan.scan_facility_state} {scan.scan_facility_zip}
                                </span>
                              </div>
                            </TableCell>
                            <TableCell>
                              <Button
                                variant="ghost"
                                size="sm"
                                onClick={() => toggleRowExpansion(index)}
                                className="transition-transform dark:hover:bg-gray-800"
                              >
                                {expandedRows[index] ? (
                                  <ChevronUp className="h-4 w-4" />
                                ) : (
                                  <ChevronDown className="h-4 w-4" />
                                )}
                              </Button>
                            </TableCell>
                          </TableRow>
                          {expandedRows[index] && (
                            <TableRow className="border-gray-200 dark:border-gray-800">
                              <TableCell colSpan={4}>
                                <div className="p-6 bg-gray-50 dark:bg-gray-800/50 rounded-md space-y-6">
                                  <div>
                                    <h4 className="font-medium mb-2 dark:text-white">Event Description</h4>
                                    <p className="text-gray-600 dark:text-gray-300">
                                      {scan.eventCodeInfo?.userDescription}
                                    </p>
                                  </div>

                                  {scan.eventCodeInfo?.stopTheClockScan && (
                                    <div className="flex items-center text-green-600 dark:text-green-400 bg-green-50 dark:bg-green-900/30 p-3 rounded-md">
                                      <Info className="h-4 w-4 mr-2" />
                                      <span>Final delivery scan recorded</span>
                                    </div>
                                  )}

                                  <div className="border-t border-gray-200 dark:border-gray-800 pt-4">
                                    <h4 className="font-medium mb-2 dark:text-white">Technical Details</h4>
                                    <dl className="grid grid-cols-1 md:grid-cols-2 gap-x-4 gap-y-2 text-sm">
                                      <div>
                                        <dt className="text-gray-500 dark:text-gray-400">Event Code</dt>
                                        <dd className="dark:text-white">{scan.scan_event_code}</dd>
                                      </div>
                                      <div>
                                        <dt className="text-gray-500 dark:text-gray-400">Machine</dt>
                                        <dd className="dark:text-white">{scan.machine_name}</dd>
                                      </div>
                                      <div>
                                        <dt className="text-gray-500 dark:text-gray-400">Equipment</dt>
                                        <dd className="dark:text-white">{scan.eventCodeInfo?.equipmentDescription}</dd>
                                      </div>
                                      <div>
                                        <dt className="text-gray-500 dark:text-gray-400">Mail Type</dt>
                                        <dd className="dark:text-white">{scan.eventCodeInfo?.mailType}</dd>
                                      </div>
                                    </dl>
                                  </div>
                                </div>
                              </TableCell>
                            </TableRow>
                          )}
                        </React.Fragment>
                      ))}
                      {addressInfo && (
                        <TableRow className="border-gray-200 dark:border-gray-800">
                          <TableCell className="font-medium">
                            <div className="flex flex-col">
                              <span className="dark:text-white">
                                {new Date().toLocaleDateString('en-US', {
                                  weekday: 'long',
                                  month: 'long',
                                  day: 'numeric',
                                  year: 'numeric'
                                })}
                              </span>
                            </div>
                          </TableCell>
                          <TableCell className="dark:text-white">Envelope Created</TableCell>
                          <TableCell>
                            <div className="flex flex-col">
                              <span className="dark:text-white">{addressInfo.origin.city}</span>
                              <span className="text-sm text-gray-500 dark:text-gray-400">
                                {addressInfo.origin.state} {addressInfo.origin.zipCode}
                              </span>
                            </div>
                          </TableCell>
                          <TableCell></TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </div>
                {/* Mobile view */}
                <div className="md:hidden">
                  {trackingData?.scans.map((scan, index) => (
                    <TrackingEventMobile
                      key={index}
                      scan={scan}
                      index={index}
                      isExpanded={expandedRows[index]}
                      onToggle={() => toggleRowExpansion(index)}
                    />
                  ))}
                  {addressInfo && (
                    <div className="border border-gray-200 dark:border-gray-700 rounded-lg p-4">
                      <div className="space-y-1">
                        <div className="font-medium dark:text-white">
                          Envelope Created
                        </div>
                        <div className="text-sm text-gray-500 dark:text-gray-400">
                          {new Date().toLocaleDateString('en-US', {
                            weekday: 'long',
                            month: 'long',
                            day: 'numeric',
                            year: 'numeric'
                          })}
                        </div>
                      </div>
                      <div className="mt-2">
                        <div className="text-sm dark:text-white">{addressInfo.origin.city}</div>
                        <div className="text-sm text-gray-500 dark:text-gray-400">
                          {addressInfo.origin.state} {addressInfo.origin.zipCode}
                        </div>
                      </div>
                    </div>
                  )}
                </div>

                {!trackingData && addressInfo && (
                  <div className="mt-6 p-6 bg-yellow-50 dark:bg-yellow-900/30 rounded-md">
                    <div className="flex items-center text-yellow-700 dark:text-yellow-400">
                      <AlertCircle className="h-5 w-5 mr-2" />
                      <span>This envelope has not been processed by USPS yet.</span>
                    </div>
                  </div>
                )}
              </CardContent>

            </Card>
          </>
        )}
      </div>
    </ApplicationLayout>
  );


}
export default App;

const TrackingEventMobile = ({ scan, index, isExpanded, onToggle }: {
  scan: any;
  index: number;
  isExpanded: boolean;
  onToggle: () => void;
}) => (
  <div className="border border-gray-200 dark:border-gray-700 rounded-lg mb-4 last:mb-0">
    <div className="p-4">
      <div className="flex justify-between items-start">
        <div className="space-y-1">
          <div className="font-medium dark:text-white">
            {scan.eventCodeInfo?.trackingTitle || scan.mail_phase}
          </div>
          <div className="text-sm text-gray-500 dark:text-gray-400">
            {new Date(scan.scan_date_time).toLocaleDateString('en-US', {
              weekday: 'long',
              month: 'long',
              day: 'numeric',
              year: 'numeric'
            })}
          </div>
          <div className="text-sm text-gray-500 dark:text-gray-400">
            {new Date(scan.scan_date_time).toLocaleTimeString('en-US', {
              hour: 'numeric',
              minute: '2-digit',
              hour12: true
            })}
          </div>
        </div>
        <Button
          variant="ghost"
          size="sm"
          onClick={onToggle}
          className="transition-transform dark:hover:bg-gray-800"
        >
          {isExpanded ? (
            <ChevronUp className="h-4 w-4" />
          ) : (
            <ChevronDown className="h-4 w-4" />
          )}
        </Button>
      </div>

      <div className="mt-2">
        <div className="text-sm dark:text-white">{scan.scan_facility_name}</div>
        <div className="text-sm text-gray-500 dark:text-gray-400">
          {scan.scan_facility_city}, {scan.scan_facility_state} {scan.scan_facility_zip}
        </div>
      </div>
    </div>

    {isExpanded && (
      <div className="border-t border-gray-200 dark:border-gray-700 p-4 bg-gray-50 dark:bg-gray-800/50 space-y-4">
        {/* Same expanded content as before */}
        <div>
          <h4 className="font-medium mb-2 dark:text-white">Event Description</h4>
          <p className="text-gray-600 dark:text-gray-300">
            {scan.eventCodeInfo?.userDescription}
          </p>
        </div>

        {scan.eventCodeInfo?.stopTheClockScan && (
          <div className="flex items-center text-green-600 dark:text-green-400 bg-green-50 dark:bg-green-900/30 p-3 rounded-md">
            <Info className="h-4 w-4 mr-2" />
            <span>Final delivery scan recorded</span>
          </div>
        )}

        <div className="border-t border-gray-200 dark:border-gray-700 pt-4">
          <h4 className="font-medium mb-2 dark:text-white">Technical Details</h4>
          <dl className="grid grid-cols-1 gap-y-2 text-sm">
            <div>
              <dt className="text-gray-500 dark:text-gray-400">Event Code</dt>
              <dd className="dark:text-white">{scan.scan_event_code}</dd>
            </div>
            <div>
              <dt className="text-gray-500 dark:text-gray-400">Machine</dt>
              <dd className="dark:text-white">{scan.machine_name}</dd>
            </div>
            <div>
              <dt className="text-gray-500 dark:text-gray-400">Equipment</dt>
              <dd className="dark:text-white">{scan.eventCodeInfo?.equipmentDescription}</dd>
            </div>
            <div>
              <dt className="text-gray-500 dark:text-gray-400">Mail Type</dt>
              <dd className="dark:text-white">{scan.eventCodeInfo?.mailType}</dd>
            </div>
          </dl>
        </div>
      </div>
    )}
  </div>
);