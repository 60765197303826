import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Search } from 'lucide-react';
import { Button } from "./components/ui/button";
import { Input } from "./components/ui/input";

const HomePage = () => {
    const [trackingNumber, setTrackingNumber] = useState('');
    const [error, setError] = useState('');
    const navigate = useNavigate();

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        //const value = e.target.value.replace(/[^0-9]/g, ''); // Only allow numbers
        if (e.target.value.length <= 12) { // Limit to 12 digits
            setTrackingNumber(e.target.value);
            setError('');
        }
    };

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        if (trackingNumber.length === 12) {
            navigate(`/${trackingNumber}`);
        } else {
            setError('Please enter a valid 12-digit tracking number');
        }
    };

    return (
        <div className="min-h-screen flex flex-col items-center justify-center bg-white dark:bg-gray-900">
            <div className="text-center space-y-8 px-4">
                {/* Logo */}
                <div className="flex items-baseline justify-center gap-2 mb-12">
                    <h1 className="text-5xl md:text-6xl font-extrabold text-gray-900 dark:text-white">
                        kypsh
                    </h1>
                    <span className="text-blue-500 dark:text-blue-400 text-xl font-medium">
                        beta
                    </span>
                </div>
                <div className="text-center">
                    <p className="mt-2 text-sm text-gray-500 dark:text-gray-400">
                        Enter a tracking number to get detailed delivery information.
                    </p>
                </div>

                {/* Search Box */}
                <div className="max-w-md w-full mx-auto">
                    <form onSubmit={handleSubmit} className="flex flex-col gap-2">
                        <div className="flex gap-2">
                            <Input
                                type="text"
                                placeholder="Enter tracking number"
                                value={trackingNumber}
                                onChange={handleInputChange}
                                className={`h-12 text-lg px-4 bg-white dark:bg-gray-800 ${error ? 'border-red-500 dark:border-red-400' : ''
                                    }`}
                                maxLength={12}
                            />
                            <Button
                                type="submit"
                                size="lg"
                                className="h-12 px-6 hover:bg-gray-800 hover:bg-opacity-50"
                                disabled={trackingNumber.length !== 12}
                            >
                                <Search className="h-5 w-5" />
                            </Button>
                        </div>

                        {/* Character count */}
                        <div className="flex justify-between text-sm">
                            <span className={`${error ? 'text-red-500 dark:text-red-400' : 'text-gray-500 dark:text-gray-400'}`}>
                                {error || `${trackingNumber.length}/12 digits`}
                            </span>
                            {trackingNumber.length > 0 && (
                                <button
                                    type="button"
                                    onClick={() => {
                                        setTrackingNumber('');
                                        setError('');
                                    }}
                                    className="text-gray-500 dark:text-gray-400 hover:text-gray-700 dark:hover:text-gray-200"
                                >
                                    Clear
                                </button>
                            )}
                        </div>

                        {/* Helper text */}
                        {/**/}
                    </form>
                </div>
            </div>

            {/* Footer */}
            <footer className="absolute bottom-0 w-full p-4 text-center text-sm text-gray-500 dark:text-gray-400">
                © {new Date().getFullYear()} Kypsh. All rights reserved.
            </footer>
        </div>
    );
};

export default HomePage;