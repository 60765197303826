'use client';
import Container from "./container";
import Navbar from "./navbar";

export function ApplicationLayout({ children }: { children: React.ReactNode }) {
  return (
    <div className="bg-slate-950 min-h-screen dark">
      <Navbar />
      <Container>{children}</Container>
    </div>
  )
}