import React, { useState } from 'react';
import { Search } from 'lucide-react';
import { Button } from "./ui/button"
import { Input } from "./ui/input"
import { Link } from 'react-router-dom';

const Navbar = () => {
    const [trackingNumber, setTrackingNumber] = useState('');

    const handleTrackingSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        if (trackingNumber) {
            window.location.href = `/${trackingNumber}`;
        }
    };

    return (
        <nav className="bg-white dark:bg-gray-800 shadow-sm">
            <div className="max-w-full px-4 sm:px-6 lg:px-8">
                {/* Mobile Layout (stacked) */}
                <div className="md:hidden flex flex-col py-4 space-y-4">
                    {/* Logo centered on mobile */}
                    <Link to="/" className="flex justify-center items-baseline gap-0.5">
                        <span className="text-xl font-extrabold text-gray-900 dark:text-white">kypsh</span>
                        <span className="text-blue-400 opacity-80 text-xs font-medium">beta</span>
                    </Link>

                    {/* Full-width search on mobile */}
                    <form onSubmit={handleTrackingSubmit} className="flex w-full items-center gap-2">
                        <Input
                            type="text"
                            placeholder="Enter tracking number"
                            value={trackingNumber}
                            onChange={(e) => setTrackingNumber(e.target.value)}
                            className="h-10 bg-white dark:bg-gray-700 text-gray-900 dark:text-white"
                        />
                        <Button
                            type="submit"
                            size="default"
                            variant="ghost"
                            className="h-10 w-10 flex-shrink-0 p-0 flex items-center justify-center text-gray-700 dark:text-gray-200 hover:bg-gray-100 dark:hover:bg-gray-600"
                        >
                            <Search className="h-5 w-5" />
                        </Button>
                    </form>
                </div>

                {/* Desktop Layout */}
                <div className="hidden md:flex items-center h-16 relative">
                    {/* Logo - absolute positioned to the left */}
                    <Link to="/" className="absolute flex items-baseline gap-0.5">
                        <span className="text-xl font-extrabold text-gray-900 dark:text-white">kypsh</span>
                        <span className="text-blue-400 opacity-80 text-xs font-medium">beta</span>
                    </Link>

                    {/* Centered search form */}
                    <div className="flex-1 flex justify-center">
                        <form onSubmit={handleTrackingSubmit} className="flex items-center gap-2">
                            <Input
                                type="text"
                                placeholder="Enter tracking number"
                                value={trackingNumber}
                                onChange={(e) => setTrackingNumber(e.target.value)}
                                className="w-[300px] h-10 bg-white dark:bg-gray-700 text-gray-900 dark:text-white"
                            />
                            <Button
                                type="submit"
                                size="default"
                                variant="ghost"
                                className="h-10 w-10 p-0 flex items-center justify-center text-gray-700 dark:text-gray-200 hover:bg-gray-100 dark:hover:bg-gray-600"
                            >
                                <Search className="h-5 w-5" />
                            </Button>
                        </form>
                    </div>
                </div>
            </div>
        </nav>
    );
};

export default Navbar;